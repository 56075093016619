import Api from '@/services/api'
import axios from 'axios'

const uploadFile = async (file) => {
  const response = await Api.post('/s3-upload-signed-url/generate', {
    file_name: file.name,
    mime_type: file.type,
    size: file.size,
  })

  const { id, url } = response.data.data

  await axios.put(url, file, {
    headers: { 'Content-Type': file.type },
  })

  return id
}

export default uploadFile
