<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">
        Create Education
      </v-toolbar-title>

      <v-spacer />
      <v-btn
        class="ml-5"
        :loading="educationLoading || uploadLoader"
        @click="submit()"
      >
        Create Education
      </v-btn>
    </v-app-bar>

    <v-form
      class="education-form"
      @submit.prevent="isUpdating ? update() : submit()"
    >
      <v-row>
        <v-col cols="4" align="center">
          <ImageUploader :form="form" v-if="!form.thumb_nail_from_s3" />
          <!-- <p v-if="!form.thumb_nail_from_s3 && !(form.photos.length > 0)">
            or
          </p>
          <v-text-field
            v-model.trim="form.thumb_nail_from_s3"
            v-if="!(form.photos.length > 0)"
            class="input__outlined--regular"
            label="Thumbnail from AWS"
            outlined
            :error-messages="form.$getError('thumb_nail_from_s3')"
            @input="form.$clearError('thumb_nail_from_s3')"
          /> -->

          <v-divider class="mb-7" />

          <div
            class="upload-container pointer"
            :class="{ 'has-error': form.$getError('video') }"
            v-if="!form.video_from_s3"
            @click="uploadThumbnail('video')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary"> Upload Introduction Video </span>
            </div>
            <span v-if="form.$getError('video')" class="error--text">{{
              form.$getError('video')[0]
            }}</span>

            <v-overlay :absolute="true" :value="uploadLoader">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
          </div>

          <v-progress-linear
            color="light-blue"
            height="10"
            v-if="!(uploadProgress === 0 || uploadProgress === 100)"
            :value="uploadProgress"
            striped
          ></v-progress-linear>

          <div
            class="upload-container pointer mt-5"
            v-if="checkIVideoThumbNail(form)"
          >
            <v-btn
              fab
              class="radius__button text-none remove-img error"
              x-small
              depressed
              @click="removeVideo()"
            >
              <v-icon small color="white">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
            <video controls :src="form.video[0].url">
              Your browser does not support the video tag.
            </video>
          </div>
          <!-- <p v-if="!form.video_from_s3 && !(form.video.length > 0)">
            or
          </p>
          <v-text-field
            v-model.trim="form.video_from_s3"
            v-if="!(form.video.length > 0)"
            class="input__outlined--regular"
            label="Video from AWS"
            outlined
            :error-messages="form.$getError('video_from_s3')"
            @input="form.$clearError('video_from_s3')"
          /> -->
        </v-col>

        <v-col cols="8">
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model.trim="form.title"
                class="input__outlined--regular"
                label="Education Title"
                outlined
                :error-messages="form.$getError('title')"
                @input="form.$clearError('title')"
              />
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="form.is_free"
                :error-messages="form.$getError('is_free')"
                label="Free"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <CategorySelectBox
                :items="categories"
                :selections="form.categories"
              />
            </v-col>
          </v-row>

          <ContentEditor v-model="form.content" />

          <!-- <h2 class="my-5">Alternative Educations</h2>
          <v-card outlined class="pa-5">
            <div class="d-flex justify-space-between align-center mb-3">
              <p>Add Alternative Educations</p>
              <v-btn @click="showAltEducationForm = true"> Add </v-btn>
            </div>

            <v-card
              flat
              outlined
              class="mb-3"
              v-if="alternative_educations.length > 0"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(altEducation, index) in alternative_educations"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      altEducation.title
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, i) in menuItems"
                          link
                          :key="i"
                        >
                          <v-list-item-title
                            @click="menuAction(item, altEducation, index)"
                            >{{ item }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-card> -->
        </v-col>
      </v-row>
    </v-form>

    <div v-if="showAltEducationForm">
      <AlternativeEducationForm
        :show="showAltEducationForm"
        :currentAltEducation="currentAltEducation"
        :isEdit="isEdit"
        @onSave="addAltEducation"
        @onUpdate="updateAltEducation"
        @onCancel="cancelAltEducation"
      />
    </div>

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
      :mediaType="mediaType"
      @startUpload="startUpload"
    ></MediaUploader>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ImageUploader from '@/components/ImageUploader'
import ContentEditor from '@/components/ContentEditor'
import AlternativeEducationForm from '@/components/education/AlternativeEducationForm'
import { mdiDotsVertical, mdiDelete, mdiClose } from '@mdi/js'
import { programMenu } from '@/utils/constants'
import { objectToFormData } from '@/utils/jsonToFormData'
import MediaUploader from '@/components/MediaUploader'
import CategorySelectBox from '@/components/education/CategorySelectBox'
import uploadFile from '@/utils/uploadFile'

export default {
  name: 'CreateEducation',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    ImageUploader,
    ContentEditor,
    AlternativeEducationForm,
    MediaUploader,
    CategorySelectBox,
  },
  computed: {
    ...mapState('education', {
      form: (state) => state.educationForm,
      educationLoading: (state) => state.educationLoading,
    }),
    ...mapState('educationCategory', {
      categories: (state) => state.categories,
    }),
    ...mapState({
      uploadProgress: (state) => state.uploadProgress,
    }),
  },
  data() {
    return {
      icons: {
        mdiDelete,
        mdiDotsVertical,
        mdiClose,
      },
      menuItems: [programMenu().EDIT, programMenu().DELETE],
      alternative_educations: [],
      currentAltEducation: {
        title: '',
        link: '',
        thumb_nail: null,
      },
      showAltEducationForm: false,
      editIndex: null,
      isEdit: false,
      mediaType: null,
      uploadLoader: false,
      imageState: null,
    }
  },
  mounted() {
    this.form.$reset()
    this.getExercieCategories()
  },
  methods: {
    ...mapActions({
      getExercieCategories: 'educationCategory/getExercieCategories',
    }),
    ...mapActions({
      createEducation: 'education/createEducation',
    }),
    startUpload() {
      this.uploadLoader = true
    },
    addAltEducation(altEducation) {
      this.alternative_educations.push(altEducation)
      this.resetCurrentEducation()
      this.showAltEducationForm = false
    },
    cancelAltEducation() {
      this.editIndex = null
      this.isEdit = false
      this.resetCurrentEducation()
      this.showAltEducationForm = false
    },
    updateAltEducation(altEducation) {
      this.alternative_educations[this.editIndex] = altEducation
      this.editIndex = null
      this.isEdit = false
      this.resetCurrentEducation()
      this.showAltEducationForm = false
    },
    menuAction(menu, altEducation, index) {
      switch (menu) {
        case programMenu().EDIT:
          this.isEdit = true
          this.currentAltEducation = altEducation
          this.editIndex = index
          this.showAltEducationForm = true
          break
        case programMenu().DELETE:
          this.alternative_educations.splice(index, 1)
          break
      }
    },
    resetCurrentEducation() {
      this.currentAltEducation = {
        title: '',
        link: '',
        thumb_nail: null,
      }
    },
    removeVideo() {
      this.form.video = []
    },
    uploadThumbnail(state) {
      if (state === 'video') {
        this.mediaType = 'video'
      } else {
        this.mediaType = 'image'
      }

      this.$refs.triggerUpload.openFile()
      this.imageState = state
    },
    onMediaLoad(data) {
      switch (this.imageState) {
        case 'video':
          this.form.video = []
          this.form.video = [
            {
              file: data.file.get('file'),
              url: data.url,
            },
          ]
          this.uploadLoader = false
          this.form.$clearError('video')
          break
      }
    },
    checkIVideoThumbNail(image) {
      return image?.video?.[0]?.url || image?.video?.url
    },
    update() {},
    async submit() {
      this.alternative_educations = this.alternative_educations.map(
        (altEducation) => {
          if (!(altEducation.thumb_nail instanceof File)) {
            altEducation.thumb_nail = altEducation.thumb_nail[0].file
          }
          return altEducation
        }
      )

      this.form.alternative_educations = this.alternative_educations
      const { photos, video, ...education } = this.form.$data()
      const form = objectToFormData(education)

      this.startUpload()
      if (photos.length > 0) {
        const thumbNailId = await uploadFile(photos[0]?.file)
        form.append('thumb_nail_id', thumbNailId)
      }
      if (video.length > 0) {
        const videoId = await uploadFile(video[0]?.file)
        form.append('video_id', videoId)
      }

      await this.createEducation(form)
      this.alternative_educations = []
      this.form.$reset()
      this.uploadLoader = false
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #fa4856;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }

  video {
    height: 300px;
    width: 100%;
  }
}

.remove-img {
  position: absolute;
  right: -15px;
  top: -15px;
}
</style>
